// assets
import { IconExternalLink, IconBook2 } from "@tabler/icons-react";

// constant
const icons = {
	IconExternalLink,
	IconBook2,
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const external = {
	id: "external",
	type: "group",
	children: [
		{
			id: "chat",
			title: "صفحه چت",
			type: "item",
			url: process.env.REACT_APP_CHAT_URL,
			icon: icons.IconExternalLink,
			breadcrumbs: false,
			isAdmin: false,
			target: true,
			external: true,
		},
		{
			id: "docs",
			title: "مستندات",
			type: "item",
			url: process.env.REACT_APP_DOCS_URL,
			icon: icons.IconBook2,
			breadcrumbs: false,
			isAdmin: false,
			target: true,
			external: true,
		},
	],
};

export default external;
